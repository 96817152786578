@charset "utf-8";

// Define defaults for each variable.

$base-font-family: "Ubuntu Mono", monospace !default;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.95 !default;
$base-line-height: 1.4 !default;

$spacing-unit:     26px !default;

$text-color:       #ffffff !default;
$background-color: #2c292d !default;
$brand-color:      #ff6087 !default;
$project-name:     #74eaf5;

$monokai-dir-color: #ffd766;
$monokai-comment-color: #6d6d6d;
$monokai-green: #a9dc76;
$monokai-orange: #fb9767;

$grey-color:       #828282 !default;
$grey-color-light: lighten($grey-color, 40%) !default;
$grey-color-dark:  darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width:    1200px !default;

$on-palm:          600px !default;
$on-laptop:        800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
  @media screen and (max-width: $device) {
    @content;
  }
}

@mixin relative-font-size($ratio) {
  font-size: $base-font-size * $ratio;
}

// Import partials.
@import
  "minima/base",
  "minima/layout",
  "minima/syntax-highlighting"
;
